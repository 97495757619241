<template>
  <ion-grid>
    <ion-row v-if="items !== null">
      <h4 v-if="items.length === 0" style="text-align: center; width: 100%">
        {{ noItems || $t('No items') }}
      </h4>
      <ion-col
        v-for="(item, i) in items"
        :key="i"
        :size="size"
        :size-lg="largeSize"
      >
        <ion-card
          button="true"
          :router-link="item.link || ''"
          style="margin: 5px;"
        >
          <div v-if="!avatar" class="image">
            <ion-img
              v-if="item.image"
              :src="item.image"
              class="grid-img"
            />
          </div>
          <ion-card-header
            :style="
              `display: flex; flex-flow: column; ${avatar &&
                'align-items: center; text-align: center;'}`
            "
          >
            <ion-avatar v-if="avatar" style="margin-bottom: 1.8rem;">
              <img
                v-if="item.image"
                :src="item.image"
                class="grid-img"
              />
              <img
                v-else
                src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y"
              />
            </ion-avatar>
            <ion-card-title style="font-size: 1.3rem;">
              {{ item.title }}
            </ion-card-title>
            <ion-card-subtitle v-if="item.subtitle" style="font-size: 0.6rem">
              {{ item.subtitle }}
            </ion-card-subtitle>
            <div v-if="item.subtitles" class="subtitles">
              <ion-card-subtitle
                v-for="(sub, index) in item.subtitles"
                :key="index"
                style="font-size: 0.6rem"
              >
                {{ sub }}
              </ion-card-subtitle>
            </div>
          </ion-card-header>
          <ion-card-content v-if="item.text">
            {{ item.text }}
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row v-else>
      <ion-col
        v-for="i in 8"
        :key="i"
        :size="size"
        :size-lg="largeSize"
      >
        <ion-card style="margin: 5px;">
          <ion-card-header>
            <div v-if="avatar" class="avatar">
              <ion-avatar>
                <ion-skeleton-text animated />
              </ion-avatar>
            </div>
            <ion-card-title>
              <ion-skeleton-text animated style="width: 100%;" />
            </ion-card-title>
            <ion-card-subtitle>
              <ion-skeleton-text animated style="height: 30px; width: 100%;" />
            </ion-card-subtitle>
          </ion-card-header>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script lang="ts">
import {
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonSkeletonText,
  IonAvatar,
  IonImg,
} from '@ionic/vue'
import { ellipsisVertical } from 'ionicons/icons'
export default {
  name: 'Grid',
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonSkeletonText,
    IonAvatar,
    IonImg,
  },
  props: {
    items: {
      type: Array,
      default: null,
    },
    size: {
      type: Number,
      default: 6,
    },
    largeSize: {
      type: Number,
      default: 3,
    },
    avatar: {
      type: Boolean,
      default: false,
    },
    noItem: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {
      ellipsisVertical,
    }
  },
}
</script>

<style scoped>
.avatar {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 1rem;
}
.subtitles {
  margin-top: 1rem;
}
</style>
