<template>
  <ion-page>
    <Header :title="$t('Friends')" back-link-header="true" />

    <ion-content>
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content />
      </ion-refresher>
      <Grid
        :items="users"
        :avatar="true"
        :large-size="6"
      />
      <ion-infinite-scroll threshold="100px" @ionInfinite="doRefresh($event)">
        <ion-infinite-scroll-content loading-spinner="circular" :loading-text="$t('Loading')" />
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/vue'
import { ellipsisVertical, addCircle } from 'ionicons/icons'
import { onMounted, ref } from 'vue'
import { getPlayers } from '@/api/api'
import Header from '@/components/header/AppHeader.vue'
import Grid from '@/components/old/Grid.vue'
export default {
  name: 'UserList',
  components: {
    IonContent,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    Header,
    Grid,
  },
  setup() {
    const users = ref()
    onMounted(() => {
      getPlayers()
        .then(res => (users.value = res))
        .catch(e => console.error(e))
    })
    const doRefresh = (event: any) => {
      getPlayers()
        .then(res => {
          users.value = res
          event.target.complete()
        })
        .catch(e => console.error(e))
    }
    return {
      ellipsisVertical,
      addCircle,
      users,
      doRefresh,
    }
  },
}
</script>

<style scoped></style>
