
import {
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonSkeletonText,
  IonAvatar,
  IonImg,
} from '@ionic/vue'
import { ellipsisVertical } from 'ionicons/icons'
export default {
  name: 'Grid',
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonSkeletonText,
    IonAvatar,
    IonImg,
  },
  props: {
    items: {
      type: Array,
      default: null,
    },
    size: {
      type: Number,
      default: 6,
    },
    largeSize: {
      type: Number,
      default: 3,
    },
    avatar: {
      type: Boolean,
      default: false,
    },
    noItem: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {
      ellipsisVertical,
    }
  },
}
